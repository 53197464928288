// Flatly 4.5.0
// Bootswatch

// Selection  ==================================================================
/* Webkit */
::selection {
  background: #dddc01;
}

/* Gecko/Mozilla */
::-moz-selection {
  background: #dddc01;
}

// Custom styles ===============================================================
body {
  background: #f2f2f2;
}

// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i&subset=latin-ext" !default;
@import url($web-font-path);

// Navbar ======================================================================

.bg-primary {
  .navbar-nav .active > .nav-link {
    color: $success !important;
  }
}

.bg-dark {
  background-color: $success !important;

  &.navbar-dark .navbar-nav {
    .nav-link:focus,
    .nav-link:hover,
    .active > .nav-link {
      color: $primary !important;
    }
  }
}

.navbar {
  padding: 2rem 1rem 1rem 1rem;

  .navbar-nav {

    .nav-item {
      margin-right: 10px;
    }
  
    .dropdown-divider {
      margin: 0;
    }
  
    .dropdown-item {
      padding-top: .35rem;
      padding-bottom: .35rem;
    }
  
    .dropdown {
      
      &:hover > .dropdown-menu {
        display: block;
      }
  
      > .dropdown-toggle:active {
        pointer-events: none;
      }
    }
  
    &.menu {
      .nav-item {
  
        &:after {
          content: "";
          display: block;
          height: 2px;
          background: $warning;
          width: 0%;
          transition: all .3s ease;
          transition-delay: .2s;
        }
  
        &.active:after {
          width: 100%;
        }
  
        .nav-link {
          color: $primary;
          text-transform: uppercase;
        }
      }
  
      .dropdown-divider {
        border-top-color: $warning;
      }
    }
  
    &.account {

      .dropdown {
        background: $primary !important;
      }

      .filter-results {
        padding: 0;
        margin: 0;
        width: 400px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;

        .dropdown-item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      
      .dropdown-menu {
        background: $primary;
      }
  
      .nav-link {
        padding-left: 20px;
        padding-right: 20px;
        color: $white !important;
        font-size: .8rem;
        border: 1px solid $primary;
  
        &:hover,
        &:active,
        &:focus {
          background: $white !important;
          color: $primary !important;
        }
      }
  
      .dropdown-item {
        color: $white;
        text-transform: none;
        font-size: .8rem;
  
        &:hover {
          color: $primary;
          background: $white;
        }
  
        &.nav-section {
          
          &.nav-title {
            cursor: default;
            color: $primary;
            background: $gray-300;
            border-bottom: 1px solid $info;
            pointer-events: none;
          }

          &.nav-content {
            color: $primary;
            background: $white;
            border-bottom: 1px solid $primary;
          }
        }

      }
  
      .dropdown-divider {
        &.border-warning {
          border-top-width: 2px;
        }
      }
    }

    &.help {
      .nav-item {
        .nav-link {
          width: 32px;
          height: 32px;
          text-align: center;
          font-size: .8rem;
          color: $primary;
          background: $white;
          border: 2px solid $primary;
          border-radius: 50%;

          &:hover {
            color: $white;
            background: $primary;
          }

          i.fas {
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }

  .nav-logout {
    font-size: 1.15rem;
  }
}

.dropdown-toggle {
  cursor: pointer;

  &:after {
    display: none;
  }
}

.dropdown-menu {
  box-shadow: $dropdown-shadow;
  border: none;
  border-top: 2px solid $white;
  padding: 0;
  margin: 0 !important;
  min-width: 100%;

  a {
    color: $primary;
    text-transform: uppercase;

    span {
      text-transform: none !important;
    }
  }
}

.language-chooser {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 10;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    list-style-position: inside;

    li {
      font-size: 12px;
      line-height: 10px;
      margin: 0;
      padding: 5px 10px;
      list-style-type: none;
      display: inline-block;
      color: $primary;
      cursor: pointer;

      &:hover {
        color: #262626;
        text-decoration: underline;
      }
    }
  }
  
}

// Buttons =====================================================================

.btn {
  white-space: normal;

  &-secondary,
  &-secondary:hover,
  &-warning,
  &-warning:hover {
    color: $white;
  }

  &.btn-picker {
    padding: 0.75rem 1.25rem;
  }
}

// Typography ==================================================================

// Tables ======================================================================

.table {

  &.table-fixed {
    table-layout: fixed;
  }

  .thead-dark th {
    background-color: $primary;
  }

  &-success,
  &-info,
  &-warning,
  &-danger {
    color: $white;
  }

  &-success {
    &, > th, > td {
      background-color: $success;
    }
  }

  &-info {
    &, > th, > td {
      background-color: $info;
    }
  }

  &-danger {
    &, > th, > td {
      background-color: $danger;
    }
  }

  &-warning {
    &, > th, > td {
      background-color: $warning;
    }
  }

  &-hover {

    .table-success:hover {
      &, > th, > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &, > th, > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &, > th, > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &, > th, > td {
        background-color: darken($warning, 5%);
      }
    }

  }

  &.table-simulations {
    table-layout: fixed;

    .simulations-subheader {
      th {
        font-weight: normal !important;
        font-size: 14px;
        line-height: 1.2rem;
        color: $gray-800;
        text-align: right;
      }
    }

    .simulations-footer {
      
      th {
        font-size: 13px;
        text-align: right;
        color: $gray-800;
      }
    }

    tbody {

      tr {

        td {
          &.competitor-price {
            width: 100px;

            input.form-control {
              padding: 0.3rem;
              text-align: right;
              font-size: 13px;
              border: none;
              background: #ffffcc;
            }
          }
        }

        &:last-child {
  
          td {
            border-bottom: 1px solid #888 !important;
          }
        }
      }
    }

    td {
      text-align: right;
      font-size: 13px;
    }

    .heavy-border {
      border-right: 1px solid #888;
    }
  }

}

// Forms =======================================================================

:disabled {
  pointer-events: none;
}

.form-control {
  color: $primary;
  padding: 0.75rem 1rem;
  height: auto;

  &:disabled,
  &[readonly] {
    background: $input-disabled-bg !important;
  }

  &.switch-company {
    padding: calc(0.75rem - 1px);
  }

  &.form-ean {
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
    font-size: 0.9375rem;
    color: $primary;
  }

  &.select-unit {
    width: 100px;
  }

  &.date-chooser {
    height: 2rem;
    padding: 0.1rem;
    border: 1px solid #d6d6d6;
  }

  &[type="number"]::-webkit-inner-spin-button, 
  &[type="number"]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
  }

  &[type="number"] {
    -moz-appearance:textfield;
  }
}

select {
  &.form-control {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM0NDQiIGQ9Ik03LjQwNiA3LjgyOGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eiIgLz4KPC9zdmc+');
    background-position: 100% 50%;
    background-repeat: no-repeat;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}

.date-picker-full {
  input[readonly] {
    background: initial !important;
  }

  input:disabled {
    background: $input-disabled-bg !important;
  }

  .DayPickerInput {
    width: 100% !important;
  }
}

.DayPickerInput-Overlay {
  z-index: 5 !important;
}

.date-picker {
  input[readonly] {
    background: initial !important;
  }

  input:disabled {
    background: $input-disabled-bg !important;
  }

  &.full-width {
    .DayPickerInput {
      width: 100% !important;
    }
  }

  &.pickers {
    .DayPickerInput {
      input {
        width: 150px !important;
      }
    }
  }

  .DayPickerInput-Overlay {
    width: 560px;
  }

  .DayPicker-Day {
    width: 35px !important;
    height: 35px !important;

    &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background: $primary;
      color: $white;
    }
    
    &.DayPicker-Day--today {
      color: $primary;
    }
  }
  
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: $warning;
    color: $white;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  &.to {
    .DayPickerInput-Overlay {
      left: initial !important;
      right: 0 !important;
    }
  }
}

select {
  option:disabled {
    color: $gray-400;
  }
}

fieldset {

  legend {
    font-size: .85rem;
    font-weight: bold;
    padding: 0 0.5rem;
  }
}


// Navs ========================================================================

.nav-tabs {

  .nav-item {
    position: relative;

    .icon-warning {
      position: absolute;
      top: 12px;
      left: 11px;
      width: 16px;
    }

    .nav-link {
      color: $primary !important;
      border-color: $white;
      
      &:hover {
        border-color: $warning $warning $white $warning !important;
      }
  
      &.active {
        color: $white !important;
        font-weight: 700 !important;
        background-color: $warning !important;
        border-color: $warning !important;
  
      }
    }
  }
}

.nav-pills {
  .nav-link {
    color: $gray-800 !important;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      color: $white !important;
      background-color: $info !important;
    }

    &.active {
      color: $white !important;
      font-weight: 700 !important;
      background-color: $warning !important;
      border-color: $warning !important;

    }
  }
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Lists ========================================================================

.list-group {
  
  .list-group-item {
    border: 0 !important;

    h4 {
      font-size: 1rem;

      .icon-warning {
        position: absolute;
        top: 1px;
        left: -19px;
        width: 16px;
      }
    }

    table td {
      font-size: 0.85rem;

      &:first-child {
        width: 30%;
      }
    }
  }
}

// Indicators ==================================================================

.close {
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.badge {
  &-secondary,
  &-warning {
    color: $white;
  }
}


.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      background-color: $value;
    }
  }

  &-light {
    &,
    & a,
    & .alert-link {
      color: $body-color;
    }
  }
}

// Progress bars ===============================================================

// Containers ==================================================================

.modal-open {
  .navigation {
    transform: translateX(calc(-50% - 17px/2)) !important;
  }

  .contract-actions {
    transform: translateX(calc(-50% - 17px/2)) !important;
  }
}

.container {
  min-height: 100vh;
  background: $white;

  $main-menu-height: 112px;
  $contracts-menu-height: 54px;

  &.navigation {
    position: fixed !important;
    top: 0;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    height: $main-menu-height !important;
    min-height: $main-menu-height !important;
    max-height: $main-menu-height !important;
    border-bottom: 1px solid $gray-300;
  }

  &.navigation-placeholder {
    height: $main-menu-height !important;
    min-height: $main-menu-height !important;
    max-height: $main-menu-height !important;
  }

  $demo-warning-height: 50px;

  &.demo-info {
    position: fixed !important;
    top: $main-menu-height;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    height: $demo-warning-height !important;
    min-height: $demo-warning-height !important;
    max-height: $demo-warning-height !important;
  }

  &.demo-info-placeholder {
    height: $demo-warning-height !important;
    min-height: $demo-warning-height !important;
    max-height: $demo-warning-height !important;
  }

  &.contract-actions {
    position: fixed !important;
    top: $main-menu-height;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    height: $contracts-menu-height !important;
    min-height: $contracts-menu-height !important;
    max-height: $contracts-menu-height !important;
    border-bottom: 1px solid $gray-300;
  }

  &.contract-actions-placeholder {
    height: $contracts-menu-height !important;
    min-height: $contracts-menu-height !important;
    max-height: $contracts-menu-height !important;
  }
}

.modal .close{
  color: $black;
}

// Tooltips =====================================================================

.tooltip {

  .tooltip-inner {
    max-width: 300px !important;
    padding: 2px;

    .tooltip-close {
      top: 8px;
      right: 8px;
      z-index: 10;
    }
    
    .tooltip-body {
      padding: 2px;
    }
  }
}

// React Typeahead ===============================================================

.special-select {
  .rbt {
    
    .rbt-input {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM0NDQiIGQ9Ik03LjQwNiA3LjgyOGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eiIgLz4KPC9zdmc+');
      background-position: 100% 50%;
      background-repeat: no-repeat;
      display: block;
    }

    .rbt-input-hint{
      display: none !important;
    }

    .rbt-aux {
      width: 50px !important;

      .rbt-close {
        margin-right: 1rem !important;
        color: $primary;
        font-size: 2.2rem;

        span {
          display: block;
          width: 60px !important;
        }
      }
    }

    .rbt-menu.dropdown-menu.show {
      width: auto !important;
    }

    .rbt-input-hint {
      overflow: hidden !important;

      input[readonly] {
        @extend .form-control;
        background: transparent !important;
        width: 100% !important;
      }
    }
  }
}


.rbt {
  
  .rbt-aux {
    width: 50px !important;

    .rbt-close {
      margin: 0 !important;
      color: $primary;
      font-size: 2.2rem;

      span {
        display: block;
        width: 60px !important;
      }
    }
  }

  .rbt-menu.dropdown-menu.show {
    width: auto !important;
  }

  .rbt-input-hint {
    overflow: hidden !important;

    input[readonly] {
      @extend .form-control;
      background: transparent !important;
      width: 100% !important;
    }
  }
}

// Custom Styles ===============================================================

iframe {
  border: 0;
}

footer {
  background: $blue;

  .links-info {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    
    a {
      font-size: 18px;
      color: $white;
    }
  }

  .legal-info {
    color: $white;
    font-size: 12px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    > div {
      margin: 5px 15px;
      text-align: center;

      span {
        color: $yellow;
      }
  
      a {
        color: $white;
      }
    }

  }
}

.title {
  font-size: 1.5rem;
}

.form-title {
  font-size: 1.6rem;
  color: $blue;
}

.picker-select {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM0NDQiIGQ9Ik03LjQwNiA3LjgyOGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eiIgLz4KPC9zdmc+');
  background-position: 100% 50%;
  background-repeat: no-repeat;
  display: block;
}

.section-header {
  display: flex;
  align-items: flex-end;
  height: 150px;
  color: $white;
  font-size: 32px;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0,0,0,.5);
  background-position: 0 60%;
  background-size: cover;
  background-color: $info;
  filter: saturate(80%);

  > img {
    width: 150px;
    position: relative;
    top: 80px;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));

    @include media-breakpoint-down(sm) {
      top: 50px;
      width: 75px;
    }
  }
}

.info-page {
  h2 {
    margin: 3rem 0 1.5rem;
    font-size: 2rem;
    color: $warning;
  }

  p {
    margin: 1rem 0;
  }

  ul {
    margin-top: 1rem;

    li {
      margin: 0.25rem 0;
    }
  }

  a {
    color: $primary;
  }

  strong {
    font-weight: bold;
  }

  img {
    box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.5);
  }
}

.companies-list {

  tr {

    .commands {
      width: 30px;
      vertical-align: middle;

      i.fas {
        display: none;
      }

    }
    
    &:hover {

      .commands {
        i.fas {
          display: block !important;
        }
      }
    }
  }
}

.ean-dropdown {
  position: relative;

  .form-control {
    cursor: default !important;

    &[readonly]:not(:disabled) {
      background: $white !important;
    }
  }

  label {
    display: inline-block;
  }

  .menu {
    width: 600px;
    max-height: 300px;
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 1;
    background: $white;
    box-shadow: $dropdown-shadow;
    overflow-y: auto;
  }
}

.eans-tooltip {
  text-align: left;

  ul {
    margin: 0;
    padding-left: 18px;
  }
}

.notifications-dropdown {
  position: relative;

  .notifications-menu {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 1;
    border-top: 1px solid $warning;
    box-shadow: $dropdown-shadow;

    &.download-menu {
      min-width: calc(100% + 55px);
      top: 35px !important;
    }

    &:hover {
      display: block;
    }

    > .btn {
      background: $white;
      color: $primary;
      outline: none !important;

      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }

  &:hover {
    .notifications-menu {
      display: block;
    }
  }
}

table.certificate-prices {

  tr {

    th, td {
      text-align: right;
      position: relative;

      &:first-child {
        text-align: left !important;
      }

      > div {
        display: inline-block;
        width: 100px;

        &.extra-width {
          width: 105px !important;
        }
      }
  
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      input[type="number"] {
        -moz-appearance: textfield;
      }
  
      .form-control {
        display: inline;
        padding: 3px !important;
        max-width: 100px;
        text-align: right;
        
        &.period-year {
          width: 55px;
        }
  
        &.period-month {
          width: 45px;
        }
      }

      &.command {
        width: 30px !important;
      }
    }
  }

}

.global-error {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 900000;
  transform: translateX(-50%);

  .close {
    position: relative !important;
    padding: 0 !important;
    margin-left: 1rem;
  }
}

.checkbox-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.cta-box {

  h4 {
    line-height: 2rem;
  }

  p a {
    color: black;
  }

  .btn {
    font-size: 1rem;
  }
}

.required {
  position: relative;

  &:after {
    content: '*';
    display: inline;
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 1;
    color: $danger;
  }

  &.required-label:after {
    top: 3rem !important;
  }

  &.required-select:after {
    right: 3rem !important;
  }

  &.required-input-group:after {
    right: 4rem !important;
  }

  &.required-offset-type:after {
    top: 3.25rem !important;
    right: 22.5rem !important;
  }

  &.required-offset-ean:after {
    right: 9rem !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.text-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-green {
  color: $green;
}

.border-dashed {
  border-style: dashed !important;
}

.spacer {
  height: 5rem;
}

.small-height {
  height: 100px;
}

.loader {
  font-size: 40px;
}

.height-300 {
  height: 300px;
}

.sim-chart-desc {
  margin-left: 60px;
}

.opacity-50 {
  opacity: .5;
}

.sim-param {
  margin-right: 5px;
}

.sim-val {
  font-weight: bold;
  color: $primary;
}

.sim-sep {
  margin: 0 10px;
}

.sign-button-wrap {
	.tooltip {
		z-index: 5 !important;
	}
}

ol.steps li:first-child,
ol.steps li:last-child {
    min-width: 5%;
    padding-top: 1.5em;
    margin-left: 15px;
}

ol.steps li {
  min-width: 19%;
}

ol.steps li:nth-child(6) {
    min-width: 5%;
}

.steps {
  width: 100%;
  list-style: none;
  list-style-image: none;
  margin: 20px 0 20px 0;
  padding: 0;
}

.steps li {
  float: left;
  text-align: left;
  position: relative;
  color: black;
}

.steps h6 {
      padding-top: .5em;
}

.steps .dot {
    width: 3em;
    height: 3em;
    text-align: center;
    line-height: 3em;
    border-radius: 2em;
    background: $warning;
    display: inline-block;
    color: white;
    position: relative;
    font-weight: bolder;      
    font-size: 1rem;
    z-index: 2;
    cursor: pointer;
}

.steps li::after {
  content: "";
  position: absolute;
  top: 1.4em;
  left: 3em;
  width: 12em;
  height: 0.25em;
  background: $warning;
  z-index: 1;
}

.steps li:first-child::after,
.steps li:nth-last-child(-n+2)::after {
  display: none;
}
.steps .active .dot {
  background: $warning;
}
.steps .active ~ li .dot {
  background: $light;
}
.steps .active ~ li::after {
  background: $light;
}

.ibtn {
  cursor: pointer;
}

.light-brown {
  color: rgb(158, 84, 84);
}

// Print =======================================================================

@media print {

  .hide-print {
    display: none !important;
  }
}