.sim-label {
    // display: none !important;

    @media print {
        display: inline-block !important;
        margin-bottom: 8px !important;
    }
}

.show-only-print {
    display: none;

    @media print {
        display: block !important;
    }
}

@media print {
    @page {
        size: A4;
        margin: 0mm;
    }

    * {
        -webkit-print-color-adjust: exact !important; // Chrome, Safari
        print-color-adjust: exact !important;         // Firefox
    }

    body {
        background: #fff !important;
    }

    .page-break-avoid {
        page-break-inside: avoid;
    }

    .tab-content {

        &.border-warning {
            border: none !important;
        }

        &.p-4 {
            padding: 0 !important;
        }
    }

    .table-simulations {
        margin-bottom: 30px !important;
    }

    .compare-header {

        &.my-5 {
            margin-bottom: 1rem !important;
        }
    }

    .recharts-responsive-container,
    .recharts-wrapper,
    .recharts-surface {
        width: 100% !important;
    }

    .recharts-responsive-container,
    .recharts-wrapper {
        height: 315px !important;
    }

    .recharts-legend-wrapper {
        bottom: 0px !important;
    }

    // hide
    .global-error,
    .section-header,
    .navigation-placeholder,
    .navigation,
    .title,
    .nav-tabs,
    .compare-results,
    #simulate,
    .btn-export,
    .demo-info-placeholder,
    .demo-info,
    footer {
        display: none !important;
    }
}